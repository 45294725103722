import getTripleChemicalOrder from '_cdss/utils/getTripleChemicalOrder';

export const CHEMICAL_TYPES = {
  ICS: 'ics',
  LABA: 'laba',
  LAAC: 'laac',
  LABA_ICS: 'laba,ics',
  LABA_ICS_LAAC: 'laba,ics,laac',
  LTRA: 'ltra',
  PREDNISONE: 'prednisone',
  SABA: 'saba',
  SAAC: 'saac'
};

const getDoseTarget = (props = {}) => {
  const {
    chemical,
    chemicalICS,
    chemicalLABA,
    chemicalOther,
    chemicalType,
    doseICS,
    doseLABA,
    doseOther,
    icsLabaToNumber = false
  } = props;

  if (chemicalType === CHEMICAL_TYPES.ICS) {
    return doseICS;
  }

  if (chemicalType === CHEMICAL_TYPES.LABA) {
    return doseLABA;
  }

  if (chemicalType === CHEMICAL_TYPES.LABA_ICS) {
    if (icsLabaToNumber) {
      const doseString = `${doseICS}.${doseLABA}`;

      // doseLABA > 9: checking if it's two or more digits, no negative values
      return doseLABA > 9
        ? Number.parseFloat(Number.parseFloat(doseString).toFixed(2))
        : Number(doseString);
    }

    return `${doseICS}-${doseLABA}`;
  }

  if (chemicalType === CHEMICAL_TYPES.LABA_ICS_LAAC) {
    return getTripleChemicalOrder({
      chemical,
      chemicalICS,
      chemicalLABA,
      chemicalOther,
      doseICS,
      doseLABA,
      doseOther,
      delimiter: '-'
    });
  }

  return doseOther || 0;
};

export default getDoseTarget;

import { chain } from 'lodash';

const getTripleChemicalOrder = (props = {}) => {
  const {
    chemical,
    chemicalICS,
    chemicalLABA,
    chemicalOther,
    doseICS,
    doseLABA,
    doseOther,
    delimiter = '/'
  } = props;

  const chemicalSplit = chemical.split(',') || [];

  if (chemicalSplit.length === 3) {
    return chain(chemicalSplit)
      .map(item => {
        if (item.trim() === chemicalICS) {
          return doseICS.toString();
        }

        if (item.trim() === chemicalLABA) {
          return doseLABA.toString();
        }

        if (item.trim() === chemicalOther) {
          return doseOther.toString();
        }

        return '';
      })
      .join(delimiter)
      .value();
  }

  return '';
};

export default getTripleChemicalOrder;

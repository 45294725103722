/* eslint no-else-return: "error" */
/*eslint-disable*/
import { chain, find, head, isNil, omit, toLower } from 'lodash';
import getMaxPuffsPerTime from './getMaxPuffsPerTime';
import isMedicationSymbicort from './isMedicationSymbicort';

const transformMedications = (props = {}) => {
  const {
    masterMedications,
    surveyOptions,
    surveyResponses,
    surveyQuestions
  } = props;

  const medications = chain(surveyResponses)
    .groupBy('responseCollectionUUID')
    .omit('undefined')
    .reduce(
      (accCollection, group, responseCollectionUUID) =>
        chain(group)
          .reduce(
            (pMed, response) => {
              let displayText = chain(response)
                .thru(_response => {
                  const { optionUUID, responseString } = _response;
                  if (optionUUID) {
                    return chain(surveyOptions)
                      .find({ optionUUID })
                      .get('optionText')
                      .value();
                  }

                  return responseString;
                })
                .value();

              const questionText = chain(surveyQuestions)
                .find({ surveyLinkUUID: response.surveyLinkUUID })
                .get('questionText')
                .toLower()
                .trim()
                .value();

              const keyMapper = {
                'medication style': 'device',
                'medication brand': 'name',
                'medication package': 'dose',
                'number of puffs (each time you use it)': 'puffsPerTime',
                'number of pills (each time you use it)': 'puffsPerTime',
                frequency: 'timesPerDay',
                usage: 'functionType',
                'medication tag': 'tag',
                meds_yellow_tag: 'tagYellow'
              };

              if (mappedKeyQuestionText !== 'tagYellow') {
                displayText = toLower(displayText);
              }

              const displayMapper = {
                'number of puffs (each time you use it)': parseInt(
                  displayText.replace(/(( )+puffs)$/g, ''),
                  10
                ),
                'number of pills (each time you use it)': parseInt(
                  displayText.replace(/(( )+pills)$/g, ''),
                  10
                ),
                frequency: parseInt(
                  displayText.replace(/(( )+times per day)$/g, ''),
                  10
                )
              };

              const mappedKeyQuestionText = keyMapper[questionText];
              const mappedDisplayQuestionText = displayMapper[questionText];
              let mappedKeyQuestionValue =
                mappedDisplayQuestionText || displayText;

              if (
                mappedKeyQuestionText === 'name' ||
                mappedKeyQuestionText === 'dose'
              ) {
                // convention to have dose last if contains dose,
                // replace is taking care of legacy values
                const valueSplit = mappedKeyQuestionValue.split(' ') || [];
                const valueLast = valueSplit[valueSplit.length - 1] || '';

                const doseString = valueLast
                  .replace(/µg/g, '')
                  .replace(/mcg/g, '')
                  .replace(/mg/g, '')
                  .replace(/ug/g, '');

                const isDoseValidNumber = /(([0-9\\.]+)\/){0,2}([0-9\\.]+)$/g.test(
                  doseString
                );

                if (isDoseValidNumber) {
                  const canConvert = /^\d+$/.test(doseString);
                  if (mappedKeyQuestionText === 'name') {
                    pMed.dose = canConvert ? Number(doseString) : doseString; // eslint-disable-line no-param-reassign
                  } else if (mappedKeyQuestionText === 'dose') {
                    mappedKeyQuestionValue = canConvert
                      ? Number(doseString)
                      : doseString;
                  }
                }
              }

              const pMedUpdated = chain(pMed)
                .thru(_pMed => {
                  if (
                    mappedKeyQuestionText === 'name' &&
                    mappedKeyQuestionValue.toLowerCase() === 'unsure'
                  ) {
                    const optionIcon = chain(surveyOptions)
                      .find({ optionUUID: response.optionUUID })
                      .get('optionIcon')
                      .value();

                    // example: med_ellipta_unsure -> unsure
                    // example: med_twisthaler_asmanex_unsure -> asmanex
                    const packageName = optionIcon.split('_')[2];

                    if (packageName !== 'unsure') {
                      return Object.assign({}, _pMed, {
                        [mappedKeyQuestionText]: packageName
                      });
                    }
                  }

                  return Object.assign({}, _pMed, {
                    [mappedKeyQuestionText]: mappedKeyQuestionValue
                  });
                })
                .omit('undefined')
                .thru(_pMed => {
                  if (_pMed.device) {
                    // eslint-disable-next-line no-param-reassign
                    _pMed.device = chain(_pMed.device)
                      .replace(/( <.+)+/g, '')
                      .thru(text => {
                        const mapper = {
                          'inhaler with round mouthpiece': 'inhaler1',
                          'inhaler with rectangular mouthpiece': 'inhaler2'
                        };

                        return mapper[text] || text;
                      })
                      .value();
                  }

                  if (
                    _pMed.name &&
                    _pMed.name.indexOf('bricanyl (terbutaline)') >= 0
                  ) {
                    _pMed.dose = 500; // eslint-disable-line no-param-reassign
                  }

                  if (_pMed.name) {
                    // eslint-disable-next-line no-param-reassign
                    _pMed.name = chain(_pMed.name)
                      .replace(/ .+/g, '')
                      .thru(text => {
                        const mapper = {
                          'apo-salbutamol': 'Apo-Salbutamol',
                          'teva-salbutamol': 'Teva-Salbutamol',
                          salbutamol: 'Salbutamol (Sanis)'
                        };

                        return mapper[text] || text;
                      })
                      .value();
                  }

                  if (_pMed.name && _pMed.name.indexOf('prednisone') >= 0) {
                    _pMed.daysPerSession = _pMed.timesPerDay; // eslint-disable-line no-param-reassign
                  }

                  if (isNil(_pMed.daysPerSession)) {
                    _pMed.daysPerSession = 0; // eslint-disable-line no-param-reassign
                  }

                  if (_pMed.puffsPerTime && _pMed.puffsPerTime === 'unsure') {
                    _pMed.timesPerDay = 0; // eslint-disable-line no-param-reassign
                  }

                  return _pMed;
                })
                .value();

              return pMedUpdated;
            },
            {
              responseCollectionUUID
            }
          )
          .thru(pMed => {
            const controllerAndReliever =
              pMed.functionType !== undefined &&
              pMed.functionType.toLowerCase() ===
                'regularly and also whenever i have symptoms (as a reliever)';
            const relieverOnly =
              pMed.functionType !== undefined &&
              pMed.functionType.toLowerCase() ===
                'only when i have symptoms (as a reliever)';
            // Accounts for Symbicort reliever when conditions below are met
            const symbicortRelieverUnsure =
              pMed.timesPerDay !== undefined &&
              pMed.functionType !== undefined &&
              pMed.name === 'symbicort' &&
              pMed.timesPerDay === 'occasionally–not regularly' &&
              pMed.functionType.toLowerCase() === 'unsure';

            const unsureDose = pMed.name && pMed.dose && pMed.dose === 'unsure';

            const unsureFunctionType =
              pMed.functionType && pMed.functionType === 'unsure';

            const med = Object.assign({}, omit(pMed, 'functionType'), {
              asReliever: controllerAndReliever,
              unsureFunct: unsureFunctionType
            });
            if (unsureDose) {
              med.functionType = 'controller';
            }

            if (relieverOnly || symbicortRelieverUnsure) {
              med.functionType = 'reliever';
            }

            return med;
          })
          .thru(pMed => {
            const doseSplit = pMed.dose ? pMed.dose.toString().split('/') : [];

            const match = chain(masterMedications)
              .find(mastMed => {
                const doseCondition1 =
                  mastMed.doseLABA === pMed.dose ||
                  mastMed.doseICS === pMed.dose ||
                  mastMed.doseOther === pMed.dose ||
                  (pMed.dose === 'unsure' &&
                    mastMed.dose !== pMed.dose &&
                    mastMed.functionType === 'controller');

                const doseCondition2 =
                  doseSplit.length === 2 &&
                  (mastMed.doseLABA === parseFloat(doseSplit[1]) &&
                    mastMed.doseICS === parseFloat(doseSplit[0]));

                const doseCondition3 =
                  doseSplit.length === 3 &&
                  (mastMed.doseLABA === parseFloat(doseSplit[0]) ||
                    mastMed.doseOther === parseFloat(doseSplit[0]) ||
                    mastMed.doseICS === parseFloat(doseSplit[0])) &&
                  (mastMed.doseLABA === parseFloat(doseSplit[1]) ||
                    mastMed.doseOther === parseFloat(doseSplit[1]) ||
                    mastMed.doseICS === parseFloat(doseSplit[1])) &&
                  (mastMed.doseLABA === parseFloat(doseSplit[2]) ||
                    mastMed.doseOther === parseFloat(doseSplit[2]) ||
                    mastMed.doseICS === parseFloat(doseSplit[2]));
                const functionTypesMatch =
                  mastMed.functionType === pMed.functionType ||
                  pMed.functionType === undefined;

                if (pMed.dose !== undefined) {
                  return (
                    mastMed.device === pMed.device &&
                    mastMed.name.toLowerCase() === pMed.name.toLowerCase() &&
                    functionTypesMatch &&
                    (doseCondition1 || doseCondition2 || doseCondition3)
                  );
                }

                return (
                  mastMed.device === pMed.device && mastMed.name === pMed.name
                );
              })
              .value();

            console.log("HERE'S THE MED: ", pMed);
            console.log('Found MasterMed: ', match);
            return chain(match)
              .omit(['asReliever'])
              .thru(mastMed => {
                // checking to see if these properties were in the original medication
                // if they were, use the value,
                // if not, use 0 value
                // If medication is pills, use master medication value
                if (
                  mastMed.device === 'pills' &&
                  mastMed.name !== 'prednisone' &&
                  !pMed.timesPerDay
                ) {
                  return Object.assign({}, mastMed, pMed, {
                    dose: match
                      ? mastMed.doseICS || mastMed.doseLABA || mastMed.doseOther
                      : 0,
                    timesPerDay:
                      mastMed.timesPerDay && mastMed.timesPerDay.length === 1
                        ? head(mastMed.timesPerDay)
                        : 0
                  });
                }

                if (pMed.dose === 'unsure') {
                  return Object.assign({}, mastMed, pMed, {
                    dose: pMed.dose,
                    functionType: 'controller',
                    puffsPerTime:
                      mastMed.name === 'prednisone'
                        ? 1
                        : pMed.puffsPerTime || 0,
                    timesPerDay: pMed.timesPerDay || 0
                  });
                }

                return Object.assign({}, mastMed, pMed, {
                  dose:
                    mastMed.doseICS || mastMed.doseLABA || mastMed.doseOther,
                  puffsPerTime:
                    mastMed.name === 'prednisone' ? 1 : pMed.puffsPerTime || 0,
                  timesPerDay: pMed.timesPerDay || 0
                });
              })
              .value();
          })
          .thru(med => {
            const correspondingReliever = chain(med)
              .thru(_med => {
                const {
                  asReliever,
                  doseICS,
                  doseLABA,
                  doseOther,
                  functionType,
                  name
                } = _med;

                if (asReliever && functionType === 'controller') {
                  return (
                    find(masterMedications, {
                      doseICS,
                      doseLABA,
                      doseOther,
                      functionType: 'reliever',
                      name
                    }) ||
                    find(masterMedications, {
                      functionType: 'reliever',
                      name
                    })
                  );
                }

                return false;
              })
              .value();

            if (correspondingReliever) {
              const maxPuffsPerTime = isMedicationSymbicort({ medication: med })
                ? getMaxPuffsPerTime({
                    puffsPerTime: med.puffsPerTime,
                    timesPerDay: med.timesPerDay
                  })
                : 0;

              return [med].concat(
                Object.assign({}, correspondingReliever, {
                  // dose: reliever.doseICS || reliever.doseLABA || reliever.doseOther || 0,
                  dose: med.doseICS || med.doseLABA || med.doseOther || 0,
                  // Maximum [8- (controller #puffs)* controller timesPerDay] puffs
                  maxPuffsPerTime,
                  timesPerDay: head(correspondingReliever.timesPerDay),
                  responseCollectionUUID: med.responseCollectionUUID
                })
              );
            }

            // If the medication is taken 0 times per day and puffsPerTime is not unsure then it must be a reliever
            if (
              isMedicationSymbicort({ medication: med }) &&
              med.dose !== 'unsure' &&
              med.puffsPerTime !== 'unsure' &&
              med.timesPerDay === 0
            ) {
              return Object.assign(med, {
                functionType: 'reliever'
              });
            }

            // to account for Symbicort controller if condition below is met
            if (
              isMedicationSymbicort({ medication: med }) &&
              (med.puffsPerTime === 'unsure' || med.dose === 'unsure')
            ) {
              return Object.assign(med, {
                functionType: 'controller'
              });
            }

            if (
              isMedicationSymbicort({ medication: med }) &&
              med.functionType === 'reliever'
            ) {
              return Object.assign(med, {
                maxPuffsPerTime: 0
              });
            }
            return med;
          })
          .thru(med => accCollection.concat(med))
          .value(),
      []
    )
    // .uniqBy('id')
    .value();
  return medications;
};

export default transformMedications;
